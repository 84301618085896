<template>
    <div class="user_photo">
        <!-- Loader overlay -->
        <div v-if="changeAvatarLoader" class="avatar-update-overlay">
            <v-progress-circular :size="50" :width="5" color="#20C4F5" indeterminate></v-progress-circular>
        </div>

        <div v-if="!avatar.loaded" class="skeleton-loader"></div>
        
        <v-img
            :src="`${avatar.small}?cache=${avatar.key}`"
            v-bind:srcset="avatar.srcset ? `${avatar.small}?cache=${avatar.key} 1x, ${avatar.srcset}?cache=${avatar.key} 2x` : null"
            loading="lazy"
            @load="avatarLoaded"
            :class="{'default-image': isImageDefault, 'show': avatar.loaded}"
            @click="$refs['modal-image'].show()"
            alt="User profile avatar"
        ></v-img>

        <modalImage 
            ref="modal-image" 
            :image="`${avatar.original}?cache=${avatar.key}`"
            @update-cache-key="updateCacheKey"
        />
    </div>
</template>

<script>
const ModalImage = () => import("@/components/public/modalImage");

export default {
    name: "UserProfileAvatar",
    components: {
        ModalImage
    },
    props: {
        userLogo: {
            type: Object,
            default: () => ({})
        },
        isLoading: Boolean,
        routeParams: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isImageDefault: true,
            avatar: {
                original: '',
                small: '',
                srcset: '',
                loaded: false,
                key: new Date().getTime(),
            },
            changeAvatarLoader: false,
        }
    },
    watch: {
        userLogo: {
            handler() {
                if (this.userLogo && this.userLogo['240x270']) {
                    this.getAvatar();
                }
            },
            deep: true
        },
        routeParams: {
            immediate: true,
            handler() {
                this.resetImageState();
            }
        }
    },
    mounted() {
        this.getAvatar();
    },
    methods: {
        avatarLoaded() {
            this.avatar.loaded = true;
            this.changeAvatarLoader = false;
        },
        resetImageState() {
            this.avatar = {
                small: require('@/assets/image/svg/default_avatar.svg'),
                srcset: '',
                original: require('@/assets/image/svg/default_avatar.svg'),
                loaded: false,
                key: new Date().getTime()
            };
            this.isImageDefault = true;
            this.$emit('avatar-reset');
        },
        getAvatar() {
            this.changeAvatarLoader = true;
            this.avatar.loaded = false;

            // Set default first
            this.avatar.small = require('@/assets/image/svg/default_avatar.svg');
            
            if (this.userLogo && this.userLogo['240x270']) {
                this.avatar.small = this.userLogo['240x270'];
                this.avatar.srcset = this.userLogo['240x270@2x'];
                this.avatar.original = this.userLogo['960x1080'] || require('@/assets/image/svg/default_avatar.svg');
                this.isImageDefault = false;
            }

            this.avatar.key = new Date().getTime();

            // Emit update event
            this.$emit('avatar-updated', {
                isDefault: this.isImageDefault,
                smallUrl: this.avatar.small,
                srcset: this.avatar.srcset,
                original: this.avatar.original,
                key: this.avatar.key
            });
        },
        updateCacheKey(newKey) {
            this.avatar.key = newKey;
            this.$emit('update-cache-key', newKey);
            this.getAvatar();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
