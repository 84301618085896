<template>
	<userProfileLayout>
		<div class="user_profile" data-page="user-profile">
			<div class="content-page">
				<!-- Match private profile's loader structure -->
				<div class="head_profile" v-if="loader">
					<div class="profile-header-container">
						<headSkeletonLoader parent="user-profile" />
					</div>
				</div>
				
				<!-- Profile content -->
				<div v-else class="head_profile">
					<!-- Move background outside but keep it first child of head_profile -->
					<div 
						v-if="!avatarData.isDefault" 
						class="profile_head_background" 
						:style="{
							'background-image': `url(${avatarData.smallUrl}?cache=${avatarData.key})`
						}"
					></div>
					<div v-else class="profile_head_background default"></div>

					<!-- Keep existing container structure -->
					<div class="profile-header-container">
						<userProfileAvatar
							:userLogo="PROFILE_USER_LOGO"
							:isLoading="loader"
							:routeParams="$route.params"
							@avatar-updated="handleAvatarUpdate"
							@avatar-reset="handleAvatarReset"
							@update-cache-key="handleCacheKeyUpdate"
						/>

						<div class="user_info">
							<div class="user_name">
								<div class="name-badge-wrapper">
									{{ PROFILE_USER.fullName }}
									<!-- Premium badge -->
									<template v-if="PROFILE_USER.subscription">
										<v-tooltip top close-delay="0" transition="false">
											<template v-slot:activator="{ on, attrs }">
												<div class="profile_badge" v-bind="attrs" v-on="on"
													 :class="{'premium': PROFILE_USER.subscription === 'premium', 'founder': PROFILE_USER.isFounder === true}">
												</div>
											</template>
											<div v-if="PROFILE_USER.subscription === 'premium' && PROFILE_USER.isFounder === false">Premium account</div>
											<div v-if="PROFILE_USER.subscription === 'premium' && PROFILE_USER.isFounder === true">Founder's account</div>
										</v-tooltip>
									</template>
								</div>
							</div>

							<div class="user_location" v-if="PROFILE_USER.address">
								{{ PROFILE_USER.address }}
							</div>

							<div class="follows">
								<div class="followers">
									<span class="count">{{ PROFILE_USER.followersCount }}</span>
									<div>Followers</div>
								</div>
								<div class="following">
									<span class="count">{{ PROFILE_USER.followingCount }}</span>
									<div>Following</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="wrap_profile_music_events">
					<div class="head_profile_music_events">
						<v-container>
							<v-tabs
								background-color="transparent"
								color="#fff"
								v-model="tabs">
								<v-tab v-for="tab in tabsSections" :key="tab.index">
									{{ tab.name }}
								</v-tab>
							</v-tabs>
							<div class="actions">
								<renderActionButtonSkeletons />
								
								<template v-if="actionButtonsLoader === false && this.AUTH_DATA.accessToken">
									<!-- Follow Button -->
									<template v-if="followBtnLoader">
										<div class="skeleton-loader action-button-skeleton"></div>
									</template>
									<template v-else>
										<button type="button" class="follow_btn" v-if="this.isFollow === false" @click="follow(PROFILE_USER.id)">
											<span class="icon"></span>
											Follow
										</button>
										<button type="button" class="follow_btn following" v-else @click="unfollow(PROFILE_USER.id)" 
											@mouseover="hoverFollowingButton = true"
											@mouseleave="hoverFollowingButton = false">
											<span class="icon"></span>
											<template v-if="hoverFollowingButton === true">Unfollow</template>
											<template v-else>Following</template>
										</button>
									</template>

									<button type="button" class="message_btn" @click="showComingSoonDialog = true">
										<span class="icon"></span>
										Message
									</button>
									<button v-if="!copiedUserLink" type="button" class="share_btn" @click="copyUserLink">
										<span class="material-icons" style="margin-right: 8px;">share</span>
										Share
									</button>
									<button v-if="copiedUserLink" type="button" class="share_btn">
										<span class="material-icons" style="margin-right: 8px;">check</span>
										Copied!
									</button>
								</template>

								<template v-if="actionButtonsLoader === false && !this.AUTH_DATA.accessToken">
									<button type="button" class="follow_btn" @click="dialogNeedLoginVisible = true">
										<span class="icon"></span>
										Follow
									</button>
									<button type="button" class="message_btn" @click="dialogNeedLoginVisible = true">
										<span class="icon"></span>
										Message
									</button>
									<button v-if="!copiedUserLink" type="button" class="share_btn" @click="copyUserLink">
										<span class="material-icons" style="margin-right: 8px;">share</span>
										Share
									</button>
									<button v-if="copiedUserLink" type="button" class="share_btn">
										<span class="material-icons" style="margin-right: 8px;">check</span>
										Copied!
									</button>
								</template>

								<!-- More menu -->
								<v-menu
									transition="slide-y-transition"
									:close-on-content-click="true"
									offset-y
									nudge-left="21"
									nudge-bottom="10"
									origin="top center"
									content-class="menu-pointer charcoal">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
											<span class="material-icons">more_vert</span>
										</v-btn>
									</template>
									<v-list>
										<v-list-item>
											<button type="button" v-ripple class="report">Report</button>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</v-container>
					</div>

					<div class="content_profile_music_events">
						<v-tabs-items touchless v-model="tabs">
							<!-- Music Tab -->
							<v-tab-item :transition="false">
								<div class="music_section">
									<div class="recently_liked">
										<div class="container">
											<div class="head_profile_content">
												<ContentHeader>
													<template #title>Recently liked</template>
													<template #action v-if="!recentlyLikedAlbumsLoader && USER_RECENTLY_LIKED_ALBUMS.length">
														<v-btn outlined @click="showComingSoonDialog = true">Library</v-btn>
													</template>
												</ContentHeader>
											</div>
											<div class="wrap_music">
												<template v-if="recentlyLikedAlbumsLoader">
													<albumGridSkeletonLoader v-for="index in 6" :key="index"
													                         :parent="'default_grid_albums'"></albumGridSkeletonLoader>
												</template>
												<template v-if="!recentlyLikedAlbumsLoader && USER_RECENTLY_LIKED_ALBUMS.length">
													<albumGrid v-for="album in USER_RECENTLY_LIKED_ALBUMS.slice(0, 6)"
													           :key="album.id"
													           :album="album"
													           :playZone="USER_RECENTLY_LIKED_ALBUMS">
													</albumGrid>
												</template>
											</div>
											<nothingHereYet v-if="!recentlyLikedAlbumsLoader && !USER_RECENTLY_LIKED_ALBUMS.length"></nothingHereYet>
										</div>
									</div>
								</div>
							</v-tab-item>

							<!-- Events Tab -->
							<v-tab-item :transition="false">
								<div class="events_section">
									<div class="uploaded_events">
										<v-container>
											<div class="head_profile_content">
												<ContentHeader>
													<template #title>Coming up</template>
												</ContentHeader>
											</div>
											<nothingHereYet></nothingHereYet>
										</v-container>
									</div>
								</div>
							</v-tab-item>

							<!-- About Tab -->
							<v-tab-item :transition="false">
								<div class="about_section">
									<v-container class="v_container_info">
										<div class="head_profile_content">
											<ContentHeader>
												<template #title>Info</template>
											</ContentHeader>
										</div>
										<div class="wrap_about_section">
											<div class="artist_projects" v-if="USER_ARTISTS.length">
												<div class="label_">Projects:</div>
												<div class="list_projects">
													<div class="projects" v-for="artist in USER_ARTISTS" :key="artist.id">
														<div @click="redirectToArtistPage(artist.id, artist.identifier)">
															{{ artist.name.trim() }}
														</div>
														<span v-if="USER_ARTISTS.length > 1">,</span>
													</div>
												</div>
											</div>
										</div>
									</v-container>

									<v-container>
										<div class="head_profile_content">
											<ContentHeader>
												<template #title>Bio</template>
											</ContentHeader>
										</div>
										<div class="wrap_about_section">
											<div class="_location" v-if="PROFILE_USER.address">
												{{ PROFILE_USER.address }}
											</div>
											<div class="_description" v-if="PROFILE_USER.description" v-html="formattedBio">
											</div>
											<template v-else>
												<nothingHereYet></nothingHereYet>
											</template>
										</div>
									</v-container>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</div>

			<!-- Dialogs -->
			<ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog" />
			<dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible" />
			<errorDialog v-if="errorDialog" v-model="errorDialog" />
		</div>
	</userProfileLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userProfileLayout from '@/layouts/profiles/userProfileLayout.vue';
import albumGrid from '@/components/musicReleases/albumGrid.vue';
import headSkeletonLoader from '@/components/small/skeleton-loaders/headSkeletonLoader.vue';
import nothingHereYet from '@/components/small/nothingHereYet.vue';
import actionButtonSkeletons from '@/mixins/actionButtonSkeletons';
import ContentHeader from '@/components/ContentHeader.vue';
import userProfileAvatar from '@/components/profile/userProfileAvatar.vue';

const ComingSoonDialog = () => import('@/components/dialogs/comingSoonDialog.vue');
const dialogNeedLogin = () => import('@/components/dialogs/needLogin.vue');
const errorDialog = () => import('@/components/dialogs/error.vue');

import isMobile from '@/mixins/isMobile';
import checkAccessToken from '@/mixins/checkAccessToken';
import albumGridSkeletonLoader from '@/components/small/skeleton-loaders/albumGridSkeletonLoader.vue';

export default {
    name: 'user-profile',
    props: ['id', 'name'],
    components: {
        userProfileLayout,
        albumGrid,
        ComingSoonDialog,
        dialogNeedLogin,
        errorDialog,
        headSkeletonLoader,
        albumGridSkeletonLoader,
        nothingHereYet,
        ContentHeader,
        userProfileAvatar
    },
    mixins: [checkAccessToken, isMobile, actionButtonSkeletons],
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            tabs: null,
            tabsSections: [
                {index: 0, name: 'Music'},
                {index: 1, name: 'Events'},
                {index: 2, name: 'About'}
            ],
            shareLink: `${window.location.hostname}/user/${this.id}`,
            showComingSoonDialog: false,
            dialogNeedLoginVisible: false,
            errorDialog: false,
            loader: false,
            recentlyLikedAlbumsLoader: false,
            isFollow: false,
            hoverFollowingButton: false,
            actionButtonsLoader: false,
            followBtnLoader: false,
            messageBtnLoader: false,
            supportBtnLoader: false,
            copiedUserLink: false,
            avatarData: {
                isDefault: true,
                smallUrl: '',
                srcset: '',
                original: '',
                key: new Date().getTime()
            }
        }
    },
    computed: {
        ...mapGetters([
            'AUTH_DATA',
            'PROFILE_USER',
            'USER_RECENTLY_LIKED_ALBUMS',
            'USER_ARTISTS',
            'HAS_FOLLOWED_USER',
            'USER_LOGO',
            'PROFILE_USER_LOGO'
        ]),
        formattedBio() {
            return this.PROFILE_USER.description ? this.PROFILE_USER.description.replace(/\n/g, '<br>') : '';
        }
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler() {
                this.getUserData();
            }
        },
        PROFILE_USER_LOGO: {
            immediate: true,
            deep: true,
            handler(newVal) {
                console.log('PROFILE_USER_LOGO changed:', {
                    hasData: !!newVal,
                    '240x270': newVal?.['240x270'],
                    '240x270@2x': newVal?.['240x270@2x'],
                    '960x1080': newVal?.['960x1080']
                });
            }
        }
    },
    methods: {
        ...mapActions([
            'GET_PROFILE_USER',
            'GET_HAS_FOLLOWED_USER',
            'GET_USER_ARTISTS',
            'GET_USER_RECENTLY_LIKED_ALBUMS',
            'FOLLOW',
            'UNFOLLOW',
        ]),
        handleAvatarUpdate(newAvatarData) {
            this.avatarData = { ...newAvatarData };
        },
        handleAvatarReset() {
            this.avatarData = {
                isDefault: true,
                smallUrl: '',
                srcset: '',
                original: '',
                key: new Date().getTime()
            };
        },
        handleCacheKeyUpdate(newKey) {
            this.avatarData.key = newKey;
        },
        getUserData() {
			this.loader = true;
            this.actionButtonsLoader = true;
            this.recentlyLikedAlbumsLoader = true;

            let user = this.id || this.name;

            console.log('Getting user data for:', user);

            this.GET_PROFILE_USER(user)
                .then(() => {
                    if (!this.PROFILE_USER.id) {
                        this.$router.push({name: "404"});
                        return;
                    }

                    console.log('Profile user data loaded:', {
                        id: this.PROFILE_USER.id,
                        logo: this.PROFILE_USER_LOGO
                    });

                    if (this.AUTH_DATA.accessToken) {
                        this.GET_HAS_FOLLOWED_USER(this.PROFILE_USER.id)
                            .then(() => {
                                this.isFollow = this.HAS_FOLLOWED_USER;
                            })
                            .catch(err => console.log(`GET_HAS_FOLLOWED_USER, ${err}`))
                            .finally(() => {
                                this.actionButtonsLoader = false;
                            });
                    } else {
                        this.actionButtonsLoader = false;
                    }

                    this.getRecentlyLikedAlbums(this.PROFILE_USER.id);
                    this.getUserArtists(this.PROFILE_USER.id);
                })
                .catch(err => {
                    console.error(`GET_PROFILE_USER, ${err}`);
                    this.$router.push({name: "404"});
                })
                .finally(() => this.loader = false);
        },

        redirectToArtistPage(artistId, artistName) {
            this.$router.push({
                name: 'artist-profile',
                params: {
                    id: artistId,
                    name: window.toHumanFormat(artistName)
                }
            });
        },

        getUserArtists(userId) {
            const params = {owner: userId};
            this.GET_USER_ARTISTS(params)
                .catch(err => console.log(`getUserArtists, ${err}`));
        },

        getRecentlyLikedAlbums(userId) {
            this.recentlyLikedAlbumsLoader = true;
            const params = {
                user: userId,
                status: 'published',
                limit: 6
            };
            this.GET_USER_RECENTLY_LIKED_ALBUMS(params)
                .catch(err => console.log(`getRecentlyLikedAlbums, ${err}`))
                .finally(() => this.recentlyLikedAlbumsLoader = false);
        },

        follow(userId) {
            if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
                this.followBtnLoader = true;

                this.FOLLOW(userId)
                    .then(result => {
                        if (result === 'success') {
                            this.followBtnLoader = false;
                            this.isFollow = true;
                            this.PROFILE_USER.followersCount += 1;
                        } else {
                            this.errorDialog = true;
                        }
                    })
                    .catch(err => {
                        this.errorDialog = true;
                        console.error(`user follow, ${err}`)
                    })
                    .finally(() => this.followBtnLoader = false);
            } else {
                this.dialogNeedLoginVisible = true;
            }
        },

        unfollow(userId) {
            if (this.AUTH_DATA.accessToken || !this.checkAccessToken()) {
                this.followBtnLoader = true;

                this.UNFOLLOW(userId)
                    .then(result => {
                        if (result === 'success') {
                            this.followBtnLoader = false;
                            this.isFollow = false;
                            if (this.PROFILE_USER.followersCount >= 0) {
                                this.PROFILE_USER.followersCount -= 1;
                            }
                        } else {
                            this.errorDialog = true;
                        }
                    })
                    .catch(err => {
                        this.errorDialog = true;
                        console.error(`user unfollow, ${err}`)
                    })
                    .finally(() => this.followBtnLoader = false);
            } else {
                this.dialogNeedLoginVisible = true;
            }
        },

        copyUserLink() {
            navigator.clipboard.writeText(this.shareLink);
            
            this.copiedUserLink = true;
            
            setTimeout(() => {
                this.copiedUserLink = false;
            }, 2000);
        }
    },
    mounted() {
        console.log('Initial PROFILE_USER_LOGO:', this.PROFILE_USER_LOGO);
    }
}
</script>

<style lang="scss" scoped>

</style>
